import React, { useState } from 'react'
import './login.css';
import { FaUserAlt, FaLock } from 'react-icons/fa';
import { useContext } from 'react';
import { AuthContext } from '../../context/authContext/AuthContext';
import { login } from '../../context/authContext/apiCalls';
import { useNavigate } from 'react-router-dom';
import Loading from '../Loading/Loading';
function Login() {

  const navigation = useNavigate();
  const[email, setEmail] = useState('');
  const[password, setPassword] = useState('');
  const { isFetching, dispatch } = useContext(AuthContext);

  const handleLogin = (e) => {
    e.preventDefault();
    login({ email, password }, dispatch ).then(
      data => data === true 
        ? navigation('/dashboard') 
        : alert(data)
    )
  }

  return (
    <div className='login-container'>
      {isFetching && <Loading text='Please wait ......'/>}
      <div className='form-wrapper'>
        <h1>ADMIN</h1>
        <form onSubmit={handleLogin} className='login-form'>
          <div className='input-row'>
            <FaUserAlt/>
            <input 
              required
              type='email'
              value={email}
              onChange={e => setEmail(e.target.value)}
            />
          </div>
          <div className='input-row'>
            <FaLock/>
            <input 
              required
              type='password'
              value={password}
              onChange={e => setPassword(e.target.value)}
            />
          </div>
          <button disabled={isFetching}>LOGIN</button>
        </form>
      </div>
    </div>
  )
}

export default Login;