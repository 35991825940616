import { Route, Routes } from 'react-router-dom';
import Error from '../Error/Error';
import Admin from './components/Admin/Admin';
import Home from './components/Home/Home';
import Hospital from './components/Hospital/Hospital';
import Hospitals from './components/Hospitals/Hospitals';
import Enquiries from './components/Enquiries/Enquiries';
import NavMenu from './components/NavMenu/NavMenu';
import SMS from './components/SMS/SMS';
import './dashboard.css';

function Dashboard() {
  return (
    <div className='dashboard-container'>
        <NavMenu/>
        <Routes>
            <Route index element={<Home/>}/>
            <Route path='/hospitals' element={<Hospitals/>}/>
            <Route path='/hospitals/hospital/:id' element={<Hospital/>} />
            <Route path='/hospitals/hospital/new' element={<Hospital/>} />
            <Route path='/enquiries' element={<Enquiries/>}/>
            <Route path='/admin' element={<Admin/>}/>
            <Route path='/sms' element={<SMS/>}/>
            <Route path='/*' element={<Error/>}/>
        </Routes>
    </div>
  )
}

export default Dashboard