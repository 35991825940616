import React, { useContext, useState } from 'react'
import './navMenu.css';
import { GrClose, GrMenu } from "react-icons/gr";
import { Link, useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { AuthContext } from '../../../../context/authContext/AuthContext';
import { logOut } from '../../../../context/authContext/AuthAction';

function NavMenu() {

    const path = useParams();
    const { dispatch } = useContext(AuthContext);
    useEffect(() => {
            setToggle((prev) => prev = false)
    },[path])

    const [toggle, setToggle] = useState(false);
    const handleMenu = () => setToggle((prev) => !prev);

  return (
    <nav className='nav-container'>
        <div className='wrapper'>
            <h2><Link to='/dashboard' className='link'>Dashboard</Link></h2>
            { toggle 
                ? <GrClose onClick={handleMenu} className='menu-icon'/>
                : <GrMenu onClick={handleMenu} className='menu-icon'/>
            }
            <ul className={`${ toggle ? 'menu-active' : '' } list`}>
                <li><Link to='hospitals' className='link'>Hospitals</Link></li>
                <li><Link to='enquiries' className='link'>Enquiries</Link></li>
                <li><Link to='admin' className='link'>Admin</Link></li>
                <li><Link to='sms' className='link'>SMS</Link></li>
                <li><span className='link logout' onClick={() => dispatch(logOut())}>Logout</span></li>
            </ul>
        </div>
    </nav>
  )
}

export default NavMenu