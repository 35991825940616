import axios from 'axios';
import React, { useState, useEffect, useContext } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom'
import { createHospital, deleteHospital, updateHospital } from '../../../../context/hospitalContext/apiCall';
import storage from '../../../../firebase';
import { HospitalContext } from '../../../../context/hospitalContext/HospitalContext';
import './hospital.css';
import Modal from './Modal/Modal';
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { AuthContext } from '../../../../context/authContext/AuthContext';
import Loading from '../../../Loading/Loading';

function Hospital() {
    const { user } = useContext(AuthContext);
    const { id } = useParams();
    const { isAdmin } = user;
    //Edit and add mode to doctor popup modal
    const [editMode, setEditMode] = useState(false);
    const [addMode, setAddMode] = useState(false);
    //isNew checks whether the component is opened for editing of adding new hospital
    const [isNew, setIsNew] = useState(false);
    const [singleDoc, setSingleDoc] = useState({});//for loading one doc details into doctor modal
    const [singleAddress, setSingleAddress] = useState({})
    const [docs, setDocs] = useState([]);
    const [imgToUpload, setImgToUpload] = useState(null);
    const { dispatch, isFetching } = useContext(HospitalContext);
    const [loading, setLoading] = useState(false); // loading image upload to firebase

    const navigate = useNavigate();

    const [data, setData] = useState({}) //full hospital data

    const inputChangeHandler = (e) => {
        setData({...data, [e.target.name]:e.target.value})
    }

    const addressChangeHandler = (e) => {
        setSingleAddress({...singleAddress, [e.target.name]:e.target.value})
    }

    useEffect(() => {
        const getHospital = async (pathId) => {
            try{
                const res = await axios.get('hospital/'+ pathId);
                setDocs(res.data.doctors);
                setSingleAddress(res.data.address);
                setData(res.data);
            }catch(err){
                alert(err.response.data);
                navigate('/dashboard/hospitals');
            }
        }
        id !== undefined ? getHospital(id) : setIsNew(true);
    },[id, navigate])

    const editDoc = (d) => {
        setSingleDoc((prev)=> d)
        setEditMode((prev) => true)
    }

    const docChangeHandler = (newDoc) => {
        //Adding new doctor for new hospital
        if(addMode) {
            setAddMode((prev) => false);
            setDocs([...docs, {...newDoc, id: Math.random().toPrecision(5)*100000}]);
        }
        if(editMode) {
            //Editing doctors while adding new hospital(?) or updating existing hospital(:)
            isNew
                ? setDocs((prev) => [...docs.filter((d) => d.id !== newDoc.id), {...newDoc, id: Math.random().toPrecision(5)*100000}])
                : setDocs((prev) => [...docs.filter((d) => d._id !== newDoc._id), newDoc]);
            setEditMode((prev) => false)
        }
        setSingleDoc({}) 
    }

    const handleDocDel = (d) => {
        if(window.confirm("Are you sure?")){
            isNew
                ? setDocs((prev) => docs.filter((doc) => doc.id !== d.id))
                : setDocs((prev) => docs.filter((doc) => doc._id !== d._id));
        }
    }

    const closeModalHandler = () => {
        setAddMode((prev) => false)
        setEditMode((prev) => false)
    }

    const deleteHospitalHandler = () => {
        if(window.confirm("Are you sure, you want to delete?"))
            deleteHospital( {id: data._id, isAdmin: isAdmin}, dispatch )
            .then((res) => {
                res.data.message === 'deleted' 
                    ? navigate('/dashboard') 
                    : alert('deletion failed') ;
            })

    }


    const upload = (file) => { 
        setLoading((prev) => !prev);
        const storageRef = ref(storage, 'images/' + file.name);
        const uploadTask = uploadBytesResumable(storageRef, file);

        // Listen for state changes, errors, and completion of the upload.
        uploadTask.on('state_changed',
        (snapshot) => {
            // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            console.log('Upload is ' + progress + '% done');
            // console.log(snapshot.state);
        }, 
        (error) => {
            console.log(error);
            alert('Action Failed, please try again')
        }, 
        () => {
            // Upload completed successfully, now we can get the download URL
            getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            save(downloadURL);
            console.log('Action Successfull')
            setLoading((prev) => prev=isFetching)
            });
        }
        );
    }

    
    const imgUploadHandler = (e) => {
        const file = e.target.files[0];
        if(file !== undefined){
            setData({...data, img: URL.createObjectURL(file)})
            setImgToUpload((prev) => prev = file);
        }
    }

    const save = (url) => {
        isNew
            ? createHospital({...data, address: singleAddress, doctors: docs, img:(url==='') ? '' : url, isAdmin:isAdmin }, dispatch)
                .then((res) => {
                    if(res !== 'success' ){
                        return alert('Failed to add new hospital');
                    } 
                    alert('New Hospital added')
                    navigate('/dashboard') 
                })
            : updateHospital({...data, address: singleAddress, doctors: docs, img:(url==='') ? data.img : url, isAdmin:isAdmin}, dispatch)
                .then((res) => {
                    if(res !== 'success' ) {
                        return alert('Failed to update hospital. Please login again');
                    }
                    alert('Hospital Updated')
                    navigate('/dashboard') 
                })
    }

    const formSubmitHandler = (e) => {
        e.preventDefault();
        imgToUpload === null 
            ? save('')
            : upload(imgToUpload);
    }


  return (
    <div className='hform'>
        { (addMode || editMode)  && 
            <Modal  docdata={singleDoc} 
                    onCloseModal={closeModalHandler} 
                    onSaveDoc={docChangeHandler}
            />
        }
        <h2>{isNew ? 'New Hospital' : data.name }</h2>
        <hr/>
        <Link to='/dashboard/hospitals' className='link back'>back</Link>
        <div className='form-container'>
            <form onSubmit={formSubmitHandler} className='hospital-form'>
                <div className='upload-image'>
                    <img className='h-img' alt='upload img' src={data.img}/>
                    <input 
                        className='upload-btn' 
                        accept='image/*' 
                        type='file'
                        onChange={imgUploadHandler}
                    />
                </div>
                <div className='input-flex'>
                    <div className='one-input'>
                        <label>Name</label>
                        <input 
                            required
                            name='name'
                            type='text'
                            defaultValue={data.name}
                            onChange = {e => inputChangeHandler(e)} 
                        />
                    </div>
                    <div className='one-input'>
                        <label>Public-link-id</label>
                        <input
                            required 
                            name='publiclink'
                            type='text'
                            defaultValue={data.publiclink}
                            onChange={e => inputChangeHandler(e)} 
                        />
                    </div>
                </div>
                <div className='input-flex'>
                    <div className='one-input'>
                        <label>City</label>
                        <input 
                            required
                            name='city'
                            type='text'
                            defaultValue={singleAddress.city}
                            onChange={e => addressChangeHandler(e)} 
                        />
                    </div>
                    <div className='one-input'>
                        <label>State</label>
                        <input 
                            name='state'
                            required
                            type='text'
                            defaultValue={singleAddress.state}
                            onChange={e => addressChangeHandler(e)} 
                        />
                    </div>
                </div>
                <div className='input-flex'>
                    <div className='one-input'>
                        <label>Country</label>
                        <input 
                            required
                            name='country'
                            type='text'
                            defaultValue={singleAddress.country}
                            onChange={e => addressChangeHandler(e)} 
                        />
                    </div>
                    <div className='one-input'>
                        <label>pin</label>
                        <input 
                            name='pin'
                            required
                            type='text'
                            defaultValue={singleAddress.pin}
                            onChange={e => addressChangeHandler(e)} 
                        />
                    </div>
                </div>
                <div className='input-flex'>
                    <div className='one-input'>
                        <label>Waiting period</label>
                        <input 
                            name='waiting'
                            type='text'
                            defaultValue={data.waiting}
                            onChange={e => inputChangeHandler(e)} 
                        />
                    </div>
                    <div className='one-input'>
                        <label>Facilities</label>
                        <input 
                            name='facilities'
                            type='text'
                            defaultValue={data.facilities}
                            onChange={e => inputChangeHandler(e)} 
                        />
                    </div>
                </div>
                <div className='input-flex'>
                    <div className='one-input'>
                        <label>Price range</label>
                        <input 
                            name='price'
                            type='text'
                            defaultValue={data.price}
                            onChange={e => inputChangeHandler(e)} 
                        />
                    </div>
                    <div className='one-input'>
                        <label>Languages</label>
                        <input 
                            name='languages'
                            type='text'
                            defaultValue={data.languages}
                            onChange={e => inputChangeHandler(e)} 
                        />
                    </div>
                </div>
                <div className='input-flex'>
                    <div className='one-input'>
                        <label>Category</label>
                        <input 
                            name='category'
                            type='text'
                            defaultValue={data.category}
                            onChange={e => inputChangeHandler(e)} 
                        />
                    </div>
                    <div className='one-input'>
                        <label>Full Address</label>
                        <input 
                            name='fullAddress'
                            required
                            type='text'
                            defaultValue={data.fullAddress}
                            onChange={e => inputChangeHandler(e)} 
                        />
                    </div>
                </div>
                <div className='input-flex'> 
                    <div className='one-input'>
                        <label>About</label>
                        <textarea 
                            name='about'
                            required
                            type='text'
                            defaultValue={data.about}
                            onChange={e => inputChangeHandler(e)} 
                        />
                    </div>
                    <div className='one-input'>
                        <label>Hospital Information</label>
                        <textarea 
                            name='info'
                            type='text'
                            defaultValue={data.info}
                            onChange={e => inputChangeHandler(e)} 
                        />
                    </div> 
                </div>
                <div className='input-flex'>
                    <div className='one-input'>
                        <label>IsPopular</label>
                        <input 
                            placeholder='true or false'
                            name='popular'
                            type='text'
                            defaultValue={data.popular}
                            onChange={e => inputChangeHandler(e)} 
                        />
                    </div>
                    <div className='one-input'>
                        <label>Product id</label>
                        <input 
                            name='productid'
                            required
                            type='text'
                            defaultValue={data.productid}
                            onChange={e => inputChangeHandler(e)} 
                        />
                    </div>
                </div>
                <div className='button-flex'>
                    <span onClick={() => setAddMode((prev) => true)} className='add-doc'>Add New Doc</span>
                </div>

             
                    
                <div className='doclist-container'>
                    {docs.length !==0 &&
                        docs.map((d, k) => (
                            <div key={k} className='doclist'>
                                <div className='doc-info'>
                                    <span>Dr.{d.name}</span>
                                    <span className='spec'>{d.specs}</span>
                                </div>
                                <div className='delEdit'>
                                    <span className='span-btn' onClick={() => editDoc(d)}>Edit</span>
                                    <span className='span-btn' onClick={() => handleDocDel(d)}>Delete</span>
                                </div>
                            </div>
                        ))
                    }
                </div>
                        
                <div className='button-flex'>
                    <button className='link'>Save</button>
                    <Link to='/dashboard/hospitals' className='link'>close</Link>
                    { !isNew && <span onClick={deleteHospitalHandler} className='del link'>delete</span>}
                </div>
            </form>
        </div>
        { ( isFetching || loading ) && <Loading text='Saving changes .....' /> }
    </div>
  )
}

export default Hospital;