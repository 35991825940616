export const loginStart = () => ({
    type: "LOGIN_START",    
});

export const loginSuccess = (user) => ({
    type: "LOGIN_SUCCESS",
    payload: user, 
});

export const loginFailure = () => ({
    type: "LOGIN_FAILURE",
});

export const updatePasswordStart = () => ({
    type: "UPDATE_PASSWORD_START",    
});

export const updatePasswordSuccess = () => ({
    type: "UPDATE_PASSWORD_SUCCESS",
});

export const updatePasswordFailure = () => ({
    type: "UPDATE_PASSWORD_FAILURE",
});

export const logOut = () => ({
    type: "LOGOUT_SUCCESS",
});
