import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { AuthContextProvider } from './context/authContext/AuthContext';
import { HospitalContextProvider } from './context/hospitalContext/HospitalContext';
import axios from 'axios';
import { EnquiryContextProvider } from './context/enquiryContext/EnquiryContext';

axios.defaults.baseURL = 'https://api.treatat.com/api/';
// axios.defaults.baseURL = 'http://localhost:8000/api/';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
      <AuthContextProvider>
        <HospitalContextProvider>
          <EnquiryContextProvider>
            <App />
          </EnquiryContextProvider>
        </HospitalContextProvider>
      </AuthContextProvider>
);

