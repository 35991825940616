import axios from "axios";
import { deleteEnquiryFailure, deleteEnquiryStart, deleteEnquirySuccess, getAllEnquiriesFailure, getAllEnquiriesStart, getAllEnquiriesSuccess } from "./EnquiryAction";



export const getEnquiries = async (state, dispatch) => {
    dispatch(getAllEnquiriesStart());
    try{
        const res = await axios.get('enquiry/all');
        dispatch(getAllEnquiriesSuccess(res.data));
        return res.data;
      }catch(err){
        dispatch(getAllEnquiriesFailure());
        if(err.response.data === 'No enquiries found')
            return 'empty'
        else   
            return alert('unexpected error')
      }
}

export const deleteEnquiry = async (data, dispatch) => {
    // console.log(data)
    dispatch(deleteEnquiryStart());
    try{
        const res = await axios.delete("enquiry/"+data.id, {
            headers: {
                token: "Bearer "+ JSON.parse(localStorage.getItem('ta-admin')).accessToken,
            },
            data: {
                isAdmin: data.isAdmin,
            }
        });
        dispatch(deleteEnquirySuccess(res.data));
        // alert('Deleted successfully');
        return res;
    } catch(err){
        dispatch(deleteEnquiryFailure());
        // console.log(err)
        alert(err.response.data)
        return err;
    }
}