import React, { useEffect, useState, useContext } from 'react'
import './enquiries.css'
import { FaTrash } from 'react-icons/fa'

import { EnquiryContext } from '../../../../context/enquiryContext/EnquiryContext'
import { deleteEnquiry, getEnquiries } from '../../../../context/enquiryContext/apiCalls'
import { AuthContext } from '../../../../context/authContext/AuthContext'
import Loading from '../../../Loading/Loading'

function Enquiries() {

  const { user } = useContext(AuthContext);
  const { isAdmin } = user; 
  const [del, setDel] = useState(false);
  const [allEnquiries, setAllEnquiries] = useState([])
  const { dispatch, isFetching } = useContext(EnquiryContext);

  useEffect(() => {
    getEnquiries(allEnquiries, dispatch).then((res) =>{
      if(res === 'empty') return setAllEnquiries([]);
      // Sort enquiries by createdAt before setting state
      const sortedEnquiries = res.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
      setAllEnquiries(sortedEnquiries);
    })
    setDel(false);
    // eslint-disable-next-line
  },[del])

  const deleteEnquiryHandler = (id) => {
    deleteEnquiry( {id: id, isAdmin: isAdmin}, dispatch )
    .then((res) => {
        if(res.data.message === 'deleted')
          setDel((prev => !prev))
        else
          alert('Deletion Failed')
    })
  }

  const formatDate = (date) => {
    return new Date(date).toLocaleString('en-US', {
      month: 'short',
      day: 'numeric',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric'
    });
  }

  return (
    
    <div className='enquiries-container'>
        <h1>Enquiries</h1><hr/><br/>
        <p>All Enquiries</p>
        <div className='enquiries-wrapper'>
          {
            allEnquiries.map((enq) => (
              <div key={enq._id} className='enquiry-card'>
                <h3>{enq.fullname}</h3>
                <p>TicketId:<br/> <span>{enq.ticketId}</span></p>
                <p>Phone:<br/> <span>{enq.phone}</span></p>
                <p>Email:<br/> <span>{enq.email}</span></p>
                <p>Passport:<br/> { enq.passports.length === 0 && <span>Not Attached</span>}
                  { enq.passports.length !==0 &&
                    enq.passports.map((pass, id) => (
                      <span key={id}>
                        <a href={pass.link} className='file' rel='noreferrer' target='_blank'>
                          Passport-{id+1}
                        </a>&nbsp;
                      </span>
                    ))
                  }
                </p>
                <p>Medical Reports:<br/> { enq.medicalReports.length === 0 && <span>Not Attached</span>}
                  { enq.medicalReports.length !==0 &&
                    enq.medicalReports.map((med, id) => (
                      <span key={id}>
                        <a href={med.link} className='file' rel='noreferrer' target='_blank'>
                          Report-{id+1}
                        </a>&nbsp;
                      </span>
                    ))
                  }
                </p>
                <p>created:<br/><span>{formatDate(enq.createdAt)}</span></p>
                <FaTrash onClick={() => deleteEnquiryHandler(enq._id)} className='del-icon'/>
              </div>
            ))
          }
          { isFetching && <Loading/>}
        </div>
    </div>
  )
}

export default Enquiries