import EnquiryReducer from "./EnquiryReducer";
import { createContext, useReducer } from 'react';

const INITIAL_STATE = {
    enquiry: null,
    isFetching: false,
    error: false,
};

export const EnquiryContext = createContext(INITIAL_STATE);

export const EnquiryContextProvider = ({ children }) => {
    const [state, dispatch] = useReducer(EnquiryReducer, INITIAL_STATE);

    return (
        <EnquiryContext.Provider 
            value={{ 
                enquiry: state.hospital, 
                isFetching: state.isFetching, 
                error: state.error,
                dispatch
            }}
        >
            {children}
        </EnquiryContext.Provider>
    )
}