const HospitalReducer = (state, action) => {
    switch (action.type){

        case "CREATE_HOSPITAL_START":
            return{
                hospital:null,
                isFetching: true,
                error: false,
            };
        
        case "CREATE_HOSPITAL_SUCCESS":
            return{
                hospital:action.payload,
                isFetching: false,
                error: false,
            };
        
        case "CREATE_HOSPITAL_FAILURE":
            return{
                hospital:null,
                isFetching: false,
                error: true,
            };
        case "UPDATE_HOSPITAL_START":
            return{
                hospital:null,
                isFetching: true,
                error: false,
            };
        
        case "UPDATE_HOSPITAL_SUCCESS":
            return{
                hospital:action.payload,
                isFetching: false,
                error: false,
            };
        
        case "UPDATE_HOSPITAL_FAILURE":
            return{
                hospital:null,
                isFetching: false,
                error: true,
            };
        case "DELETE_HOSPITAL_START":
            return{
                hospital:null,
                isFetching: true,
                error: false,
            };
        
        case "DELETE_HOSPITAL_SUCCESS":
            return{
                hospital:action.payload,
                isFetching: false,
                error: false,
            };
        
        case "DELETE_HOSPITAL_FAILURE":
            return{
                hospital:null,
                isFetching: false,
                error: true,
            };

        case "GET_ALL_HOSPITAL_START":
            return{
                hospital:null,
                isFetching: true,
                error: false,
            };
        
        case "GET_ALL_HOSPITAL_SUCCESS":
            return{
                hospital:action.payload,
                isFetching: false,
                error: false,
            };
        
        case "GET_ALL_HOSPITAL_FAILURE":
            return{
                hospital:null,
                isFetching: false,
                error: true,
            };
            
        default:
            return { ...state }; 
        
    }
}

export default HospitalReducer;