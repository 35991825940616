const EnquiryReducer = (state, action) => {
    switch (action.type){

        case "GET_ALL_ENQUIRIES_START":
            return{
                enquiry:null,
                isFetching: true,
                error: false,
            };
        
        case "GET_ALL_ENQUIRIES_SUCCESS":
            return{
                enquiry:action.payload,
                isFetching: false,
                error: false,
            };
        
        case "GET_ALL_ENQUIRIES_FAILURE":
            return{
                enquiry:null,
                isFetching: false,
                error: true,
            };
        case "DELETE_ENQUIRY_START":
            return{
                enquiry:null,
                isFetching: true,
                error: false,
            };
        
        case "DELETE_ENQUIRY_SUCCESS":
            return{
                enquiry:action.payload,
                isFetching: false,
                error: false,
            };
        
        case "DELETE_ENQUIRY_FAILURE":
            return{
                enquiry:null,
                isFetching: false,
                error: true,
            };
            
        default:
            return { ...state }; 
        
    }
}

export default EnquiryReducer;