import React from 'react'
import { Link } from 'react-router-dom'

function Home() {
  return (
    <div className='cards'>
        <Link to='/dashboard/hospitals' className='card'>
            <span>Hospitals</span>
        </Link>
        <Link to='/dashboard/enquiries' className='card'>
            <span>Enquiries</span>
        </Link>
    </div>
  )
}

export default Home