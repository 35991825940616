export const createHospitalStart = () => ({
    type: "CREATE_HOSPITAL_START",    
});

export const createHospitalSuccess = (hospital) => ({
    type: "CREATE_HOSPITAL_SUCCESS",
    payload: hospital, 
});

export const createHospitalFailure = () => ({
    type: "CREATE_HOSPITAL_FAILURE",
});

export const updateHospitalStart = () => ({
    type: "UPDATE_HOSPITAL_START",    
});

export const updateHospitalSuccess = (hospital) => ({
    type: "UPDATE_HOSPITAL_SUCCESS",
    payload: hospital, 
});

export const updateHospitalFailure = () => ({
    type: "UPDATE_HOSPITAL_FAILURE",
});

export const deleteHospitalStart = () => ({
    type: "DELETE_HOSPITAL_START",    
});

export const deleteHospitalSuccess = (hospital) => ({
    type: "DELETE_HOSPITAL_SUCCESS",
    payload: hospital, 
});

export const deleteHospitalFailure = () => ({
    type: "DELETE_HOSPITAL_FAILURE",
});

export const getAllHospitalStart = () => ({
    type: "GET_ALL_HOSPITAL_START",    
});

export const getAllHospitalSuccess = (hospital) => ({
    type: "GET_ALL_HOSPITAL_SUCCESS",
    payload: hospital, 
});

export const getAllHospitalFailure = () => ({
    type: "GET_ALL_HOSPITAL_FAILURE",
});
