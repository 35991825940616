export const getAllEnquiriesStart = () => ({
    type: "GET_ALL_ENQUIRIES_START",    
});

export const getAllEnquiriesSuccess = (enquiry) => ({
    type: "GET_ALL_ENQUIRIES_SUCCESS",
    payload: enquiry, 
});

export const getAllEnquiriesFailure = () => ({
    type: "GET_ALL_ENQUIRIES_FAILURE",
});

export const deleteEnquiryStart = () => ({
    type: "DELETE_ENQUIRY_START",    
});

export const deleteEnquirySuccess = (enquiry) => ({
    type: "DELETE_ENQUIRY_SUCCESS",
    payload: enquiry, 
});

export const deleteEnquiryFailure = () => ({
    type: "DELETE_ENQUIRY_FAILURE",
});