// import axios from 'axios';
import React from 'react'
import './admin.css'
import { useContext } from 'react';
import { useState } from 'react'
import { AuthContext } from '../../../../context/authContext/AuthContext';
import { updatePassword } from '../../../../context/authContext/apiCalls';

function Admin() {

      const { user, dispatch } = useContext(AuthContext);
      const [ pass, setPass ] = useState(null)

      const changeHandler = (e) => {
        const value = e.target.value;
        setPass({...pass, [e.target.name]: value})
      }
      const { isAdmin, _id } = user;
      
      const submitHandler = (e) => {
        e.preventDefault();
        if( pass.p !== pass.cp || pass.cp === null || pass.cp === undefined ) return alert('both password not matching');
        if(pass.p.length < 8 ) return alert('password should be minimum 8 characters');
        updatePassword({isAdmin, _id, password: pass.p}, dispatch)
          .then((res) => {
            res === 'success'
              ? alert('Password Updated')
              : alert('Update Failed')
          })
      }

  return (
    <div className='pass-update'>
      <h2>UPDATE PASSWORD</h2>
      <form className='pass-form' onSubmit={submitHandler}>
        <input 
          type='password'
          name='p'
          required
          onChange={changeHandler}
          placeholder='New Password'
        />
        <input 
          name='cp'
          type='password'
          required
          onChange={changeHandler}
          placeholder='Confirm Password'
        />
        <button type='submit' onSubmit={e => e.preventDefault()} className='form-btn'>Save</button>
      </form>
    </div>
  )
}

export default Admin