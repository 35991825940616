import axios from 'axios';
import { createHospitalFailure, createHospitalStart, createHospitalSuccess,
        updateHospitalStart, updateHospitalSuccess, updateHospitalFailure,
        deleteHospitalStart, deleteHospitalSuccess, deleteHospitalFailure, getAllHospitalStart, getAllHospitalSuccess, getAllHospitalFailure } from './HospitalAction';

export const createHospital = async (hospital, dispatch) => {
    dispatch(createHospitalStart());
    try{
        const res = await axios.post("hospital/add", hospital);
        dispatch(createHospitalSuccess(res.data));
        return 'success'
    } catch(err){
        dispatch(createHospitalFailure());
        console.log(err)
        return 'failed';
    }
}

export const getHospitals = async (state, dispatch) => {
    dispatch(getAllHospitalStart());
    try{
        const res = await axios.get('hospital/all');
        dispatch(getAllHospitalSuccess(res.data));
        return res.data;
      }catch(err){
        dispatch(getAllHospitalFailure());
        return 'failed';
      }
}

export const updateHospital = async (hospital, dispatch) => {
    // console.log(hospital)
    dispatch(updateHospitalStart());
    try{
        const res = await axios.put("hospital/"+hospital._id, hospital,{
            headers: {
                token: "Bearer "+ JSON.parse(localStorage.getItem('ta-admin')).accessToken,
            },
        });
        dispatch(updateHospitalSuccess(res.data));
        return 'success'
    } catch(err){
        dispatch(updateHospitalFailure());
        console.log(err)
        return 'failed';
    }
}

export const deleteHospital = async (data, dispatch) => {
    
    dispatch(deleteHospitalStart());
    try{
        const res = await axios.delete("hospital/"+data.id, {
            headers: {
                token: "Bearer "+ JSON.parse(localStorage.getItem('ta-admin')).accessToken,
            },
            data: {
                isAdmin: data.isAdmin,
            }

        });
        dispatch(deleteHospitalSuccess(res.data));
        alert('Deleted successfully');
        return res;
    } catch(err){
        dispatch(deleteHospitalFailure());
        console.log(err)
        alert(err.response.data)
        return err;
    }
}