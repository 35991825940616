import axios from 'axios';
import { 
    loginFailure, loginStart, loginSuccess, 
    updatePasswordFailure, updatePasswordStart, updatePasswordSuccess } from './AuthAction';

export const login = async (user, dispatch) => {
    dispatch(loginStart());
    try{
        const res = await axios.post("auth/login", user);
        dispatch(loginSuccess(res.data));
        return(res.data.isAdmin)
    } catch(err){
        dispatch(loginFailure());
        return(err.response.data)
    }
}

//updating admin password
export const updatePassword = async (data, dispatch) => {
    dispatch(updatePasswordStart());
    try{
        await axios.put("/admin/"+data._id, data,{
          headers: {
              token: "Bearer "+ JSON.parse(localStorage.getItem('ta-admin')).accessToken,
          },
        });
        dispatch(updatePasswordSuccess())
        localStorage.removeItem('ta-admin');
        return 'success'
      }catch(err){
        dispatch(updatePasswordFailure());
        console.log(err);
        return 'failed'
      }
}