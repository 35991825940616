const AuthReducer = (state, action) => {
    switch (action.type){

        case "LOGIN_START":
            return{
                user:null,
                isFetching: true,
                error: false,
            };
        
        case "LOGIN_SUCCESS":
            return{
                user:action.payload,
                isFetching: false,
                error: false,
            };
        
        case "LOGIN_FAILURE":
            return{
                user:null,
                isFetching: false,
                error: true,
            };

        case "UPDATE_PASSWORD_START":
            return{
                user:null,
                isFetching: true,
                error: false,
            };
        
        case "UPDATE_PASSWORD_SUCCESS":
            return{
                user:null,
                isFetching: false,
                error: false,
            };
        
        case "UPDATE_PASSWORD_FAILURE":
            return{
                user:null,
                isFetching: false,
                error: true,
            };

        case "LOGOUT_SUCCESS":
            return{
                user:null,
                isFetching: false,
                error: false,
            };
            
        default:
            return { ...state }; 
        
    }
}

export default AuthReducer;