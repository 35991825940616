import React, { useState, useEffect, useContext} from 'react'
import { Link } from 'react-router-dom'
import { getHospitals } from '../../../../context/hospitalContext/apiCall';
import { HospitalContext } from '../../../../context/hospitalContext/HospitalContext';
import Loading from '../../../Loading/Loading';
import './hospitals.css'

function Hospitals() {
  const [allHospitals, setAllHospitals] = useState([])
  const { dispatch, isFetching } = useContext(HospitalContext);
  useEffect(() => {
    getHospitals(allHospitals ,dispatch).then((data) =>{
      if(data === 'failed') return alert('Unable fetch All hospitals');
      setAllHospitals(data)
    })
    // eslint-disable-next-line
  },[])

  return (
      <div className='hospitals-container'>
      <h2>HOSPITALS</h2><hr/>
      <Link className='link add-btn' to='hospital/new'>Add New</Link>
      <span className='onboard'>On board</span>
      <div className='hospitals-wrapper'>
        {allHospitals.length !==0 && 
            allHospitals.map((hospital) => (
              <div key={ hospital._id } className='hospital-card'>
                <h4>{hospital.name}</h4>
                <span>{hospital.address.city}, {hospital.address.state}</span>
                <span>Type: {hospital.category}</span>
                <span>Public link: <span className='publiclink'>{hospital.publiclink}</span></span>
                <Link 
                  to={
                    {pathname:  `hospital/${hospital.publiclink}` }
                  }

                  state = { {props: hospital} }
                  className='viewedit link'>View/Edit
                </Link>
              </div>
            ))
        }
        { isFetching &&  <Loading text='Loading ......'/>  }
      </div>
    </div>
  )
}

export default Hospitals