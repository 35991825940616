import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";

// Treatat app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCYyBYCHvSsWrdZWbf78AMAGmXluNCok0s",
  authDomain: "treatat-project-1721e.firebaseapp.com",
  projectId: "treatat-project-1721e",
  storageBucket: "treatat-project-1721e.appspot.com",
  messagingSenderId: "585455660381",
  appId: "1:585455660381:web:9573aa5169cc5ee3928cb4"
};

  const app = initializeApp(firebaseConfig);
  const storage = getStorage(app);
  export default storage;