import { useContext } from 'react';
import { BrowserRouter as Router, Navigate, Route, Routes } from 'react-router-dom';
import Dashboard from './components/Dashboard/Dashboard';
import Login from './components/Login/Login';
import { AuthContext } from './context/authContext/AuthContext';


function App() {

  const { user } = useContext(AuthContext);
 
  return (
    <>

    <Router>
      <Routes>
        <Route path='/' element={ user !== null ? <Navigate to='/dashboard'/> : <Login/>} />
        <Route path='dashboard/*' element={ user === null ? <Navigate to='/' /> : <Dashboard/> }/>
        <Route path='*' element={<Navigate to='/'/>}/> 
      </Routes>
    </Router>
    </>
  
  );
}

export default App;
