import React, { useRef, useState } from 'react'
import './sms.css'

function SMS() {

  const textAreaRef = useRef();
  console.log(textAreaRef.current);
  const [data ,setData] = useState();

  const fillMessage = (msg) => {
    textAreaRef.current.value = msg;
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    alert("Sending message to "+data.contact)
  }

  const changeHandler = (e) => {
    const value = e.target.value;
    setData({ ...data, [e.target.name]: value})
  }

  return (
    <div className='sms-container'>
      <h3>SEND SMS</h3><hr/>
      <div className='messages'>
        <p 
          className='form-btn' 
          onClick={() => fillMessage('Thank you for registering on TreatAt. Our support specialist will contact you soon.')}>
            Thank you for registering
        </p>
        <p 
          className='form-btn' 
          onClick={() => fillMessage('Hello from TreatAt. We tried contacting you via your registered mobile number. But we were unable to connect. We will try to contact you again soon.')}>
            Unable to connect
        </p>
      </div>
      <form onSubmit={handleSubmit} className='sms-form' >
        <input 
          placeholder='Enter Mobile no.'
          name='contact'
          required
          type='number'
          onChange={e => changeHandler(e)}
        />
        <textarea 
          ref={textAreaRef}
          placeholder='Message'
          name='message'
          required
          type=''
          onChange={e => changeHandler(e)}
        />
        <button className='form-btn'>Send</button>
      </form>
    </div>
  )
}

export default SMS